<template>
  <div
    :class="'justify-content-left'"
  >
    <d-dropdown-form
        v-if="!paymentData.isVisitor"
        :hide="hide"
        :show="show"
        :right="true"
        :alt="paymentData.isVisitor"
        :key="'d-drop-down' + dropDownKey"
        :identifier="'booking-pay-' + paymentData.iri"
    >
      <template v-slot:dropdownbutton>
        <d-button
            :text="'general.actions.pay'"
            :class="'d-btn-sm btn d-btn-primary-outline font-text-title'"
            @on:button-click="formKey ++;show=!show"
        />
      </template>
      <template v-slot:dropdownbody>
        <participant-pay-form
          :key="'participant-pay-form-key' + formKey"
          :payment-data="paymentData"
          :booking="booking"
          @on:participant_pay_form_reload_booking="reload"
        />
      </template>
    </d-dropdown-form>
  </div>
</template>
<script>
  export default {
    props: {
      paymentData: {
        type: Object,
        default: () => {},
      },
      booking: {
        type: Object,
        default: () => {},
      }
    },
    data: () => ({
      hide: false,
      show: false,
      formKey: 0,
      dropDownKey: 0,
    }),
    components: {
      ParticipantPayForm: () => import('@form/planning/ParticipantPayForm')
    },
    methods: {
      reload() {
        this.$emit('on:detail_booking_reload');
      }
    }
  }
</script>
<style scoped>
.icon-custom-size {
  padding: 0.125rem 0.45rem;
  color: #919194;
  opacity: 1;
}

.icon-custom-size:hover {
  color: #165873;
}

.dropdown-menu {
  min-width: 13em;
}
</style>
